import React from "react";
import Footer from "./Footer/Footer";
import CustomNavbar from "./Navbar/CustomNavbar";
import { useTranslation } from "react-i18next";

function Privacy() {
  const { t } = useTranslation();
  return (
    <div>
      <CustomNavbar />
      <div className="container" style={{ marginTop: "9rem" }}>
        <article id="post-10">
          <header className="entry-header">
            <h1 className="entry-title">{t("privacyLink")}</h1>{" "}
          </header>

          <div className="entry-content">
            <p>
              <strong>
                Welcome to the KINETIC SERVICOS DIGITAIS LTDA privacy policy.
              </strong>
            </p>
            <p>
              Kinetic respects your privacy and is committed to protecting your
              personal data. This privacy policy will inform you as to how we
              look after your personal data when you visit our website
              (regardless of where you visit it from) and tell you about your
              privacy rights and how the law protects you.
            </p>
            <ol>
              <li>
                <strong>
                  <a name="_Toc256000000"></a>Important information and who we
                  are
                </strong>
              </li>
            </ol>
            <p>Purpose of this privacy policy</p>
            <p>
              This privacy policy aims to give you information on how Kinetic
              collects and processes your personal data through your use of this
              website, including any data you may provide through this website
              when you sign up to our newsletter <strong>OR</strong> purchase a
              product or service.
            </p>
            <p>
              This website is not intended for children and we do not knowingly
              collect data relating to children.
            </p>
            <p>
              It is important that you read this privacy policy together with
              any other privacy policy or fair processing policy we may provide
              on specific occasions when we are collecting or processing
              personal data about you so that you are fully aware of how and why
              we are using your data. This privacy policy supplements other
              notices and privacy policies and is not intended to override them.
            </p>
            <p>Controller</p>
            <p>
              Kinetic is the controller and responsible for your personal data.
            </p>
            <p>
              We have appointed a data privacy manager who is responsible for
              overseeing questions in relation to this privacy policy. If you
              have any questions about this privacy policy, including any
              requests to exercise <em>your legal rights</em>, please contact
              the data privacy manager using the details set out below.
            </p>
            <p>Contact details</p>
            <p>
              If you have any questions about this privacy policy or our privacy
              practices, please contact our data privacy manager] in the
              following ways: contato@kinetic.llc
            </p>
            <p>
              Changes to the privacy policy and your duty to inform us of
              changes
            </p>
            <p>
              It is important that the personal data we hold about you is
              accurate and current. Please keep us informed if your personal
              data changes during your relationship with us.
            </p>
            <p>Third-party links</p>
            <p>
              This website may include links to third-party websites, plug-ins
              and applications. Clicking on those links or enabling those
              connections may allow third parties to collect or share data about
              you. We do not control these third-party websites and are not
              responsible for their privacy statements. When you leave our
              website, we encourage you to read the privacy policy of every
              website you visit.
            </p>
            <ol start="2">
              <li>
                <strong>
                  <a name="_Toc256000001"></a>The data we collect about you
                </strong>
              </li>
            </ol>
            <p>
              Personal data, or personal information, means any information
              about an individual from which that person can be identified. It
              does not include data where the identity has been removed
              (anonymous data).
            </p>
            <p>
              We may collect, use, store and transfer different kinds of
              personal data about you which we have grouped together as follows:
            </p>
            <ul>
              <li>
                Identity Data includes first name, maiden name, last name,
                username or similar identifier, marital status, title, date of
                birth and gender.
              </li>
              <li>
                Contact Data includes billing address, delivery address, email
                address and telephone numbers.
              </li>
              <li>
                Financial Data includes bank account and payment card details.
              </li>
              <li>
                Transaction Data includes details about payments to and from you
                and other details of products and services you have purchased
                from us.
              </li>
              <li>
                Technical Data includes internet protocol (IP) address, your
                login data, browser type and version, time zone setting and
                location, browser plug-in types and versions, operating system
                and platform, and other technology on the devices you use to
                access this website.
              </li>
              <li>
                Profile Dataincludesyour username and password, purchases or
                orders made by you, your interests, preferences, feedback and
                survey responses.
              </li>
              <li>
                Usage Data includes information about how you use our website,
                products and services.
              </li>
              <li>
                Marketing and Communications Dataincludes your preferences in
                receiving marketing from us and our third parties and your
                communication preferences.
              </li>
            </ul>
            <p>
              We also collect, use and share Aggregated Data such as statistical
              or demographic data for any purpose. Aggregated Data could be
              derived from your personal data but is not considered personal
              data in law as this data will <strong>not</strong> directly or
              indirectly reveal your identity. For example, we may aggregate
              your Usage Data to calculate the percentage of users accessing a
              specific website feature. However, if we combine or connect
              Aggregated Data with your personal data so that it can directly or
              indirectly identify you, we treat the combined data as personal
              data which will be used in accordance with this privacy policy.
            </p>
            <p>
              We do not collect any Special Categories of Personal Data about
              you (this includes details about your race or ethnicity, religious
              or philosophical beliefs, sex life, sexual orientation, political
              opinions, trade union membership, information about your health,
              and genetic and biometric data). Nor do we collect any information
              about criminal convictions and offences.
            </p>
            <p>If you fail to provide personal data</p>
            <p>
              Where we need to collect personal data by law, or under the terms
              of a contract we have with you, and you fail to provide that data
              when requested, we may not be able to perform the contract we have
              or are trying to enter into with you (for example, to provide you
              with goods or services). In this case, we may have to cancel a
              product or service you have with us but we will notify you if this
              is the case at the time.
            </p>
            <ol start="3">
              <li>
                <strong>
                  <a name="_Toc256000002"></a>How is your personal data
                  collected?
                </strong>
              </li>
            </ol>
            <p>
              We use different methods to collect data from and about you
              including through:
            </p>
            <ul>
              <li>
                <strong>Direct interactions</strong>
                <strong>.</strong> You may give us your Identity, Contact and
                Financial Databy filling in forms or by corresponding with us by
                post, phone, email or otherwise. This includes personal data you
                provide when you:
              </li>
              <li>apply for our products or services;</li>
              <li>create an account on our website;</li>
              <li>subscribe to our service or publications;</li>
              <li>request marketing to be sent to you;</li>
              <li>enter a survey; or</li>
              <li>give us feedback or contact us.</li>
              <li>
                <strong>Automated technologies or interactions.</strong> As you
                interact with our website, we will automatically collect
                Technical Data about your equipment, browsing actions and
                patterns. We collect this personal data by using cookies[,
                server logs] and other similar technologies. [We may also
                receive Technical Data about you if you visit other websites
                employing our cookies.] Please see our cookie policy [LINK] for
                further details.
              </li>
              <li>
                <strong>Third parties or publicly available sources.</strong> We
                may receive personal data about you from various third parties
                and public sources]as set out below:
              </li>
            </ul>
            <p>Technical Data from the following parties:</p>
            <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; analytics providers;</p>
            <p>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; advertising networks; and
            </p>
            <p>
              (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; search information
              providers.
            </p>
            <p>
              (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contact, Financial and
              Transaction Data from providers of technical, payment and delivery
              services.
            </p>
            <ol start="4">
              <li>
                <a name="_Toc256000003"></a>How we use your personal data
              </li>
            </ol>
            <p>
              We will only use your personal data when the law allows us to.
              Most commonly, we will use your personal data in the following
              circumstances:
            </p>
            <ul>
              <li>
                Where we need to perform the contract we are about to enter into
                or have entered into with you.
              </li>
              <li>
                Where it is necessary for our legitimate interests (or those of
                a third party) and your interests and fundamental rights do not
                override those interests.
              </li>
              <li>Where we need to comply with a legal obligation.</li>
            </ul>
            <p>
              We do rely on consent as a legal basis for processing your
              personal data. You have the right to withdraw consent to marketing
              at any time by contacting us.
            </p>
            <p>Purposes for which we will use your personal data</p>
            <p>
              We have set out below, in a table format, a description of all the
              ways we plan to use your personal data, and which of the legal
              bases we rely on to do so. We have also identified what our
              legitimate interests are where appropriate.
            </p>
            <p>
              Note that we may process your personal data for more than one
              lawful ground depending on the specific purpose for which we are
              using your data. Please contact us if you need details about the
              specific legal ground we are relying on to process your personal
              data where more than one ground has been set out in the table
              below.
            </p>
            <table>
              <tbody>
                <tr>
                  <td width="237">
                    <p>
                      <strong>Purpose/Activity</strong>
                    </p>
                  </td>
                  <td width="184">
                    <p>
                      <strong>Type of data</strong>
                    </p>
                  </td>
                  <td width="291">
                    <p>
                      <strong>
                        Lawful basis for processing including basis of
                        legitimate interest
                      </strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>To register you as a new customer</p>
                  </td>
                  <td width="184">
                    <p>(a) Identity</p>
                    <p>(b) Contact</p>
                  </td>
                  <td width="291">
                    <p>Performance of a contract with you</p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>To process and deliver your order including:</p>
                    <p>(a) Manage payments, fees and charges</p>
                    <p>(b) Collect and recover money owed to us</p>
                  </td>
                  <td width="184">
                    <p>(a) Identity</p>
                    <p>(b) Contact</p>
                    <p>(c) Financial</p>
                    <p>(d) Transaction</p>
                    <p>(e)Marketing and Communications</p>
                  </td>
                  <td width="291">
                    <p>(a) Performance of a contract with you</p>
                    <p>
                      (b) Necessary for our legitimate interests (to recover
                      debts due to us)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>
                      To manage our relationship with you which will include:
                    </p>
                    <p>
                      (a) Notifying you about changes to our terms or privacy
                      policy
                    </p>
                    <p>(b) Asking you to leave a review or take a survey</p>
                  </td>
                  <td width="184">
                    <p>(a) Identity</p>
                    <p>(b) Contact</p>
                    <p>(c) Profile</p>
                    <p>(d) Marketing and Communications</p>
                  </td>
                  <td width="291">
                    <p>(a) Performance of a contract with you</p>
                    <p>(b) Necessary to comply with a legal obligation</p>
                    <p>
                      (c) Necessary for our legitimate interests (to keep our
                      records updated and to study how customers use our
                      products/services)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>To enable you to partake in/or complete a survey</p>
                  </td>
                  <td width="184">
                    <p>(a) Identity</p>
                    <p>(b) Contact</p>
                    <p>(c) Profile</p>
                    <p>(d) Usage</p>
                    <p>(e) Marketing and Communications</p>
                  </td>
                  <td width="291">
                    <p>(a) Performance of a contract with you</p>
                    <p>
                      (b) Necessary for our legitimate interests (to study how
                      customers use our products/services, to develop them and
                      grow our business)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>
                      To administer and protect our business and this website
                      (including troubleshooting, data analysis, testing, system
                      maintenance, support, reporting and hosting of data)&nbsp;
                    </p>
                  </td>
                  <td width="184">
                    <p>(a) Identity</p>
                    <p>(b) Contact</p>
                    <p>(c) Technical</p>
                  </td>
                  <td width="291">
                    <p>
                      (a) Necessary for our legitimate interests (for running
                      our business, provision of administration and IT services,
                      network security, to prevent fraud and in the context of a
                      business reorganisation or group restructuring exercise)
                    </p>
                    <p>(b) Necessary to comply with a legal obligation</p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>
                      To deliver relevant website content and advertisements to
                      you and measure or understand the effectiveness of the
                      advertising we serve to you
                    </p>
                  </td>
                  <td width="184">
                    <p>(a) Identity</p>
                    <p>(b) Contact</p>
                    <p>(c) Profile</p>
                    <p>(d) Usage</p>
                    <p>(e) Marketing and Communications</p>
                    <p>(f) Technical</p>
                  </td>
                  <td width="291">
                    <p>
                      Necessary for our legitimate interests (to study how
                      customers use our products/services, to develop them, to
                      grow our business and to inform our marketing strategy)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>
                      To use data analytics to improve our website,
                      products/services, marketing, customer relationships and
                      experiences
                    </p>
                  </td>
                  <td width="184">
                    <p>(a) Technical</p>
                    <p>(b) Usage</p>
                  </td>
                  <td width="291">
                    <p>
                      Necessary for our legitimate interests (to define types of
                      customers for our products and services, to keep our
                      website updated and relevant, to develop our business and
                      to inform our marketing strategy)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>
                      To make suggestions and recommendations to you about goods
                      or services that may be of interest to you
                    </p>
                  </td>
                  <td width="184">
                    <p>(a) Identity</p>
                    <p>(b) Contact</p>
                    <p>(c) Technical</p>
                    <p>(d) Usage</p>
                    <p>(e) Profile</p>
                    <p>(f) Marketing and Communications</p>
                  </td>
                  <td width="291">
                    <p>
                      Necessary for our legitimate interests (to develop our
                      products/services and grow our business)
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Marketing</p>
            <p>
              We strive to provide you with choices regarding certain personal
              data uses, particularly around marketing and advertising.
            </p>
            <p>Promotional offers from us</p>
            <p>
              We may use your Identity, Contact, Technical, Usage and Profile
              Data to form a view on what we think you may want or need, or what
              may be of interest to you. This is how we decide which products,
              services and offers may be relevant for you (we call this
              marketing).
            </p>
            <p>
              You will receive marketing communications from us if you have
              requested information from us or purchased [goods or services]
              from us and you have not opted out of receiving that marketing.
            </p>
            <p>Third-party marketing</p>
            <p>
              We will get your express opt-in consent before we share your
              personal data with any third party for marketing purposes.
            </p>
            <p>Opting out</p>
            <p>
              You can ask us or third parties to stop sending you marketing
              messages at any time by contacting us at any time.
            </p>
            <p>
              Where you opt out of receiving these marketing messages, this will
              not apply to personal data provided to us as a result of a
              product/service purchase, product/service experience or other
              transactions.
            </p>
            <p>Cookies</p>
            <p>
              Our website uses cookies to distinguish you from other users of
              our website. This helps us to provide you with a good experience
              when you browse our website and allows us to improve our site.A
              cookie is a small file of letters and numbers that we store on
              your browser or the hard drive of your computer if you agree.
              Cookies contain information that is transferred to your computer’s
              hard drive.
            </p>
            <p>
              <strong>We use the following cookies:</strong>
            </p>
            <ul>
              <li>
                <strong>N</strong>
                <strong>ecessary cookies.</strong> These are cookies that are
                required for the operation of our website. They include, for
                example, cookies that enable you to log into secure areas of our
                website, use a shopping cart or make use of e-billing services.
              </li>
              <li>
                <strong>Analytical or performance cookies.</strong> These allow
                us to recognise and count the number of visitors and to see how
                visitors move around our website when they are using it. This
                helps us to improve the way our website works, for example, by
                ensuring that users are finding what they are looking for
                easily.
              </li>
              <li>
                <strong>Functionality cookies.</strong> These are used to
                recognise you when you return to our website. This enables us to
                personalise our content for you, greet you by name and remember
                your preferences (for example, your choice of language or
                region).
              </li>
              <li>
                <strong>Targeting cookies.</strong> These cookies record your
                visit to our website, the pages you have visited and the links
                you have followed. We will use this information to make our
                website and the advertising displayed on it more relevant to
                your interests. We may also share this information with third
                parties for this purpose.
              </li>
              <li>
                <strong>Media cookies (including social media ones) </strong>
                These cookies will be tracking your browser across other
                websites and find out you main interests. These cookies may be
                implemented by number of social media services which we use to
                share our content and information with your network.
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <strong>IF YOU DO NOT WANT US TO USE YOUR CO</strong>
              <strong>O</strong>
              <strong>KIES.</strong>
            </p>
            <p>
              If you do not agree with the use of cookies, you can cancel your
              account. If you access our Platform and/or if you use our services
              and a pop-up warning message about cookies appears on our website,
              you can opt to consent or to decline to share your cookies. Please
              note, if you accept the cookies policy in the pop-up window, no
              additional consent will be required.
            </p>
            <p>
              <strong>Change of purpose</strong>
            </p>
            <p>
              We will only use your personal data for the purposes for which we
              collected it, unless we reasonably consider that we need to use it
              for another reason and that reason is compatible with the original
              purpose. If you wish to get an explanation as to how the
              processing for the new purpose is compatible with the original
              purpose, please contact us.
            </p>
            <p>
              If we need to use your personal data for an unrelated purpose, we
              will notify you and we will explain the legal basis which allows
              us to do so.
            </p>
            <p>
              Please note that we may process your personal data without your
              knowledge or consent, in compliance with the above rules, where
              this is required or permitted by law.
            </p>
            <ol start="5">
              <li>
                <strong>
                  <a name="_Toc256000004"></a>Disclosures of your personal data
                </strong>
              </li>
            </ol>
            <p>
              We may share your personal data with the parties set out below for
              the purposes set out in the table [
              <em>Purposes for which we will use your personal data</em>] above.
            </p>
            <ul>
              <li>Internal Third Parties.</li>
              <li>External Third Parties.</li>
              <li>
                Third parties to whom we may choose to sell, transfer or merge
                parts of our business or our assets. Alternatively, we may seek
                to acquire other businesses or merge with them. If a change
                happens to our business, then the new owners may use your
                personal data in the same way as set out in this privacy policy.
              </li>
            </ul>
            <p>
              We require all third parties to respect the security of your
              personal data and to treat it in accordance with the law. We do
              not allow our third-party service providers to use your personal
              data for their own purposes and only permit them to process your
              personal data for specified purposes and in accordance with our
              instructions.
            </p>
            <ol start="6">
              <li>
                <strong>
                  <a name="_Toc256000005"></a>International transfers
                </strong>
              </li>
            </ol>
            <p>
              If you consent to our privacy policy this means you agree that we
              may access,&nbsp; process and transfer data on a global basis
              ,taking in consideration that we comply with the requirements of
              Data Protection Laws.
            </p>
            <ol start="7">
              <li>
                <strong>
                  <a name="_Toc256000006"></a>Data security
                </strong>
              </li>
            </ol>
            <p>
              We have put in place appropriate security measures to prevent your
              personal data from being accidentally lost, used or accessed in an
              unauthorised way, altered or disclosed. In addition, we limit
              access to your personal data to those employees, agents,
              contractors and other third parties who have a business need to
              know. They will only process your personal data on our
              instructions and they are subject to a duty of confidentiality.
            </p>
            <p>
              We have put in place procedures to deal with any suspected
              personal data breach and will notify you and any applicable
              regulator of a breach where we are legally required to do so.
            </p>
            <ol start="8">
              <li>
                <strong>
                  <a name="_Toc256000007"></a>Data retention
                </strong>
              </li>
            </ol>
            <p>How long will you use my personal data for?</p>
            <p>
              We will only retain your personal data for as long as reasonably
              necessary to fulfil the purposes we collected it for, including
              for the purposes of satisfying any legal, regulatory, tax,
              accounting or reporting requirements. We may retain your personal
              data for a longer period in the event of a complaint or if we
              reasonably believe there is a prospect of litigation in respect to
              our relationship with you.
            </p>
            <p>
              To determine the appropriate retention period for personal data,
              we consider the amount, nature and sensitivity of the personal
              data, the potential risk of harm from unauthorised use or
              disclosure of your personal data, the purposes for which we
              process your personal data and whether we can achieve those
              purposes through other means, and the applicable legal,
              regulatory, tax, accounting or other requirements.
            </p>
            <p>
              In some circumstances we will anonymise your personal data (so
              that it can no longer be associated with you) for research or
              statistical purposes, in which case we may use this information
              indefinitely without further notice to you.
            </p>
            <ol start="9">
              <li>
                <strong>
                  <a name="_Toc256000008"></a>Your legal rights
                </strong>
              </li>
            </ol>
            <p>
              Under certain circumstances, you have rights under data protection
              laws in relation to your personal data. Please click on the links
              below to find out more about these rights:
            </p>
            <ul>
              <li>
                [<em>Request access to your personal data</em>].
              </li>
              <li>
                [<em>Request correction of your personal data</em>].
              </li>
              <li>
                [<em>Request erasure of your personal data</em>].
              </li>
              <li>
                [<em>Object to processing of your personal data</em>].
              </li>
              <li>
                [<em>Request restriction of processing your personal data</em>].
              </li>
              <li>
                [<em>Request transfer of your personal data</em>].
              </li>
              <li>
                [<em>Right to withdraw consent</em>].
              </li>
            </ul>
            <p>
              If you wish to exercise any of the rights set out above, please
              contact us.
            </p>
            <p>No fee usually required</p>
            <p>
              You will not have to pay a fee to access your personal data (or to
              exercise any of the other rights). However, we may charge a
              reasonable fee if your request is clearly unfounded, repetitive or
              excessive. Alternatively, we could refuse to comply with your
              request in these circumstances.
            </p>
            <p>
              <strong>What we may need from you</strong>
            </p>
            <p>
              We may need to request specific information from you to help us
              confirm your identity and ensure your right to access your
              personal data (or to exercise any of your other rights). This is a
              security measure to ensure that personal data is not disclosed to
              any person who has no right to receive it. We may also contact you
              to ask you for further information in relation to your request to
              speed up our response.
            </p>
            <p>
              <strong>Time limit to respond</strong>
            </p>
            <p>
              We try to respond to all legitimate requests within one month.
              Occasionally it could take us longer than a month if your request
              is particularly complex or you have made a number of requests. In
              this case, we will notify you and keep you updated.
            </p>
            <ol start="10">
              <li>
                <strong>
                  <a name="_Toc256000009"></a>Glossary
                </strong>
              </li>
            </ol>
            <p>
              <strong>LAWFUL BASIS</strong>
            </p>
            <p>
              Legitimate Interest means the interest of our business in
              conducting and managing our business to enable us to give you the
              best service/product and the best and most secure experience. We
              make sure we consider and balance any potential impact on you
              (both positive and negative) and your rights before we process
              your personal data for our legitimate interests. We do not use
              your personal data for activities where our interests are
              overridden by the impact on you (unless we have your consent or
              are otherwise required or permitted to by law). You can obtain
              further information about how we assess our legitimate interests
              against any potential impact on you in respect of specific
              activities by contacting us.
            </p>
            <p>
              Performance of Contract means processing your data where it is
              necessary for the performance of a contract to which you are a
              party or to take steps at your request before entering into such a
              contract.
            </p>
            <p>
              Comply with a legal obligation means processing your personal data
              where it is necessary for compliance with a legal obligation that
              we are subject to.
            </p>
            <p>
              <strong>THIRD PARTIES</strong>
            </p>
            <p>Internal Third Parties</p>
            <p>
              Other Kinetic affiliates&nbsp; [acting as joint controllers or
              processors] and who are based in other jurisdictions and provide
              IT and system administration services and undertake leadership
              reporting.
            </p>
            <p>
              <strong>External Third Parties</strong>
            </p>
            <ul>
              <li>
                Service providers [acting as processors] based other
                jurisdictions who provide IT and system administration services.
              </li>
              <li>
                Professional advisers acting as processors or joint controllers
                including lawyers, bankers, auditors and insurers based in UAE
                or other jurisdictions who provide consultancy, banking, legal,
                insurance and accounting services.
              </li>
              <li>
                HM Revenue &amp; Customs, regulators and other authorities
                [acting as processors or joint controllers] based UAE who
                require reporting of processing activities in certain
                circumstances.
              </li>
              <li>
                Any other thirds parties who provide market research or other
                services or with whoch Kinetic had a contract and provides them
                a service.
              </li>
            </ul>
            <p>
              <strong>YOUR LEGAL RIGHTS</strong>
            </p>
            <p>You have the right to:</p>
            <p>
              Request accessto your personal data (commonly known as a “data
              subject access request”). This enables you to receive a copy of
              the personal data we hold about you and to check that we are
              lawfully processing it.
            </p>
            <p>
              Request correctionof the personal data that we hold about you.
              This enables you to have any incomplete or inaccurate data we hold
              about you corrected, though we may need to verify the accuracy of
              the new data you provide to us.
            </p>
            <p>
              Request erasureof your personal data. This enables you to ask us
              to delete or remove personal data where there is no good reason
              for us continuing to process it. You also have the right to ask us
              to delete or remove your personal data where you have successfully
              exercised your right to object to processing (see below), where we
              may have processed your information unlawfully or where we are
              required to erase your personal data to comply with local law.
              Note, however, that we may not always be able to comply with your
              request of erasure for specific legal reasons which will be
              notified to you, if applicable, at the time of your request.
            </p>
            <p>
              Object to processingof your personal data where we are relying on
              a legitimate interest (or those of a third party) and there is
              something about your particular situation which makes you want to
              object to processing on this ground as you feel it impacts on your
              fundamental rights and freedoms. You also have the right to object
              where we are processing your personal data for direct marketing
              purposes. In some cases, we may demonstrate that we have
              compelling legitimate grounds to process your information which
              override your rights and freedoms.
            </p>
            <p>
              Request restriction of processingof your personal data. This
              enables you to ask us to suspend the processing of your personal
              data in the following scenarios:
            </p>
            <ul>
              <li>If you want us to establish the data’s accuracy.</li>
              <li>
                Where our use of the data is unlawful but you do not want us to
                erase it.
              </li>
              <li>
                Where you need us to hold the data even if we no longer require
                it as you need it to establish, exercise or defend legal claims.
              </li>
              <li>
                You have objected to our use of your data but we need to verify
                whether we have overriding legitimate grounds to use it.
              </li>
            </ul>
            <p>
              Request the transferof your personal data to you or to a third
              party. We will provide to you, or a third party you have chosen,
              your personal data in a structured, commonly used,
              machine-readable format. Note that this right only applies to
              automated information which you initially provided consent for us
              to use or where we used the information to perform a contract with
              you.
            </p>
            <p>
              Withdraw consent at any timewhere we are relying on consent to
              process your personal data. However, this will not affect the
              lawfulness of any processing carried out before you withdraw your
              consent. If you withdraw your consent, we may not be able to
              provide certain products or services to you. We will advise you if
              this is the case at the time you withdraw your consent.
            </p>
          </div>
        </article>
      </div>
      <Footer />
    </div>
  );
}
export default Privacy;
