import React from "react";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function FooterLink() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <section className="footer-alt bg-dark pt-3 pb-3">
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <p className="copyright text-white f-14 fw-light mb-0">
                {new Date().getFullYear()} © KINETIC SERVICOS DIGITAIS LTDA.
                CNPJ 47.966.513/0001-15. {t("allRightsReserved")}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
