import React from "react";
import Footer from "./Footer/Footer";
import CustomNavbar from "./Navbar/CustomNavbar";
import { useTranslation } from "react-i18next";

function PrivacyPt() {
  const { t } = useTranslation();
  return (
    <div>
      <CustomNavbar />
      <div className="container" style={{ marginTop: "9rem" }}>
        <article id="post-10">
          <header className="entry-header">
            <h1 className="entry-title">{t("privacyLink")}</h1>{" "}
          </header>

          <div className="entry-content">
            <p>
              <strong>
                Bem vindo à KINETIC SERVICOS DIGITAIS LTDA política de
                Privacidade.
              </strong>
            </p>
            <p>
              A Kinetic respeita a sua privacidade e está empenhada em proteger
              seus dados pessoais. Esta política de privacidade irá informá-lo
              sobre como nós cuidamos dos seus dados pessoais quando você visita o
              nosso site (independentemente de onde você o visite) e falar sobre
              seus direitos de privacidade e como a lei o protege.
            </p>
            <ol>
              <li>
                <strong>
                  <a name="_Toc256000000"></a>Informações importantes e quem nós
                  somos
                </strong>
              </li>
            </ol>
            <p>Finalidade desta política de privacidade</p>
            <p>
              Esta política de privacidade visa fornecer informações sobre como
              a Kinetic coleta e processa seus dados pessoais por meio do uso
              deste site, incluindo quaisquer dados que você possa fornecer
              através deste site quando você se inscreve em nossa newsletter{" "}
              <strong>OU</strong> compra um produto ou serviço.
            </p>
            <p>
              Este site não se destina a crianças e não temos intenção de recolher dados relativos a crianças.
            </p>
            <p>
              É importante que você leia esta política de privacidade juntamente
              com qualquer outra política de privacidade ou política de
              processamento justo que possamos fornecer em ocasiões específicas
              quando estamos coletando ou processando dados pessoais sobre você
              para que você esteja totalmente ciente de como e por que estamos
              usando seus dados. Esta política de privacidade complementa outros
              avisos e políticas de privacidade e não se destina a
              substituí-los.
            </p>
            <p>Controlador</p>
            <p>
              Kinetic é o controlador e responsável por seus dados pessoais.
            </p>
            <p>
              Nomeamos um gerente de privacidade de dados que é responsável por
              supervisionando questões relacionadas a esta política de
              privacidade. Se você tiver alguma dúvida sobre esta política de
              privacidade, incluindo qualquer pedidos para exercer{" "}
              <em>seus direitos legais</em>, por favor entre em contato o
              gerente de privacidade de dados usando os detalhes descritos
              abaixo.
            </p>
            <p>Detalhes do contato</p>
            <p>
              Se você tiver alguma dúvida sobre esta política de privacidade ou
              nossa privacidade práticas, entre em contato com nosso gerente de
              privacidade de dados] no seguintes maneiras: alex@kinetic.llc
            </p>
            <p>
              Alterações na política de privacidade e seu dever de nos informar
              sobre mudanças
            </p>
            <p>
              É importante que os dados pessoais que mantemos sobre você sejam
              preciso e atual. Por favor, mantenha-nos informados se o seu
              alterações de dados durante seu relacionamento conosco.
            </p>
            <p>links de terceiros</p>
            <p>
              Este site pode incluir links para sites de terceiros, plug-ins e
              aplicações. Clicar nesses links ou habilitar esses conexões podem
              permitir que terceiros coletem ou compartilhem dados sobre vocês.
              Não controlamos esses sites de terceiros e não somos responsáveis
              ​​por suas declarações de privacidade. Quando você sai do nosso
              site, nós encorajamos você a ler a política de privacidade de cada
              site que você visita.
            </p>
            <ol start="2">
              <li>
                <strong>
                  <a name="_Toc256000001"></a>Os dados que coletamos sobre você
                </strong>
              </li>
            </ol>
            <p>
              Dados pessoais, ou informações pessoais, significa qualquer
              informação sobre um indivíduo a partir do qual essa pessoa pode
              ser identificada. Isto não inclui dados onde a identidade foi
              removida (dados anônimos).
            </p>
            <p>
              Podemos coletar, usar, armazenar e transferir diferentes tipos de
              dados pessoais sobre você que agrupamos da seguinte forma:
            </p>
            <ul>
              <li>
                Dados de identidade incluem nome, nome de solteira, sobrenome,
                nome de usuário ou identificador semelhante, estado civil,
                título, data de nascimento e sexo.
              </li>
              <li>
                Dados de contato incluem endereço de cobrança, endereço de
                entrega, e-mail endereço e telefones.
              </li>
              <li>
                Os dados financeiros incluem detalhes da conta bancária e do
                cartão de pagamento.
              </li>
              <li>
                Dados de transação incluem detalhes sobre pagamentos de e para
                você e outros detalhes de produtos e serviços que você comprou
                de nós.
              </li>
              <li>
                Dados técnicos incluem endereço de protocolo de internet (IP),
                seu dados de login, tipo e versão do navegador, configuração de
                fuso horário e localização, tipos e versões de plug-in de
                navegador, sistema operacional e plataforma, e outras
                tecnologias nos dispositivos que você usa para acesse este site.
              </li>
              <li>
                Dados de perfil incluem seu nome de usuário e senha, compras ou
                pedidos feitos por você, seus interesses, preferências, feedback
                e respostas da pesquisa.
              </li>
              <li>
                Dados de uso incluem informações sobre como você usa nosso site,
                produtos e serviços.
              </li>
              <li>
                Dados de marketing e comunicação incluem suas preferências em
                receber marketing nosso e de terceiros e seu Preferências de
                Comunicação.
              </li>
            </ul>
            <p>
              Também coletamos, usamos e compartilhamos Dados Agregados, como
              dados estatísticos ou dados demográficos para qualquer finalidade.
              Dados agregados podem ser derivado de seus dados pessoais, mas não
              é considerado pessoal dados em lei, pois esses dados serão{" "}
              <strong>não</strong> diretamente ou revelar indiretamente sua
              identidade. Por exemplo, podemos agregar seus dados de uso para
              calcular a porcentagem de usuários que acessam um recurso
              específico do site. No entanto, se combinarmos ou conectarmos
              Dados agregados com os seus dados pessoais para que possam direta
              ou identificá-lo indiretamente, tratamos os dados combinados como
              pessoais dados que serão usados ​​de acordo com esta política de
              privacidade.
            </p>
            <p>
              Não coletamos nenhuma categoria especial de dados pessoais sobre
              você (isso inclui detalhes sobre sua raça ou etnia, religião ou
              crenças filosóficas, vida sexual, orientação sexual, política
              opiniões, filiação sindical, informações sobre sua saúde, e dados
              genéticos e biométricos). Também não coletamos nenhuma informação
              sobre condenações e infrações criminais.
            </p>
            <p>Se você não fornecer dados pessoais</p>
            <p>
              Onde precisamos coletar dados pessoais por lei, ou nos termos de
              um contrato que temos com você e você não fornece esses dados
              quando solicitado, podemos não ser capazes de cumprir o contrato
              que temos ou estão tentando entrar em contato com você (por
              exemplo, para fornecer a você com bens ou serviços). Neste caso,
              podemos ter que cancelar um produto ou serviço que você tem
              conosco, mas iremos notificá-lo se isso é o caso da época.
            </p>
            <ol start="3">
              <li>
                <strong>
                  <a name="_Toc256000002"></a>Como estão seus dados pessoais
                  coletados?
                </strong>
              </li>
            </ol>
            <p>
              Usamos métodos diferentes para coletar dados de e sobre você
              incluindo através de:
            </p>
            <ul>
              <li>
                <strong>Interações diretas</strong>
                <strong>.</strong> Você pode nos fornecer sua identidade,
                contato e Dados Financeirospreenchendo formulários ou se
                correspondendo conosco por correio, telefone, e-mail ou outro.
                Isso inclui dados pessoais que você fornecer quando você:
              </li>
              <li>solicitar nossos produtos ou serviços;</li>
              <li>criar uma conta em nosso site;</li>
              <li>subscrever o nosso serviço ou publicações;</li>
              <li>solicitar que o marketing seja enviado a você;</li>
              <li>entrar em uma pesquisa; ou</li>
              <li>nos dê feedback ou entre em contato conosco.</li>
              <li>
                <strong>Tecnologias ou interações automatizadas.</strong> Como
                você interagir com nosso site, coletaremos automaticamente Dados
                Técnicos sobre o seu equipamento, ações de navegação e padrões.
                Coletamos esses dados pessoais usando cookies[, logs do
                servidor] e outras tecnologias semelhantes. [Podemos também
                receber dados técnicos sobre você se você visitar outros sites
                usando nossos cookies.] Consulte nossa política de cookies
                [LINK] para detalhes adicionais.
              </li>
              <li>
                <strong>Terceiros ou fontes publicamente disponíveis.</strong>{" "}
                Nós pode receber dados pessoais sobre você de vários terceiros e
                fontes públicas] conforme estabelecido abaixo:
              </li>
            </ul>
            <p>Dados técnicos das seguintes partes:</p>
            <p>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; provedores de análise;
            </p>
            <p>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; redes de publicidade; e
            </p>
            <p>
              (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; pesquisar informações
              fornecedores.
            </p>
            <p>
              (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contato, Financeiro e
              Dados de transação de provedores de serviços técnicos, de
              pagamento e entrega Serviços.
            </p>
            <ol start="4">
              <li>
                <a name="_Toc256000003"></a>Como usamos seus dados pessoais
              </li>
            </ol>
            <p>
              Só utilizaremos os seus dados pessoais quando a lei nos permitir.
              Mais comumente, usaremos seus dados pessoais nas seguintes
              circunstâncias:
            </p>
            <ul>
              <li>
                Onde precisamos executar o contrato que estamos prestes a
                celebrar ou firmaram com você.
              </li>
              <li>
                Quando for necessário para nossos interesses legítimos (ou os de
                um terceiro) e seus interesses e direitos fundamentais não
                substituir esses interesses.
              </li>
              <li>Quando precisamos cumprir uma obrigação legal.</li>
            </ul>
            <p>
              Nós confiamos no consentimento como base legal para processar suas
              dados pessoais. Você tem o direito de retirar o consentimento para
              marketing a qualquer momento entrando em contato conosco.
            </p>
            <p>Finalidades para as quais usaremos seus dados pessoais</p>
            <p>
              Apresentamos a seguir, em formato de tabela, a descrição de todos
              os maneiras como planejamos usar seus dados pessoais e quais das
              bases nas quais confiamos para fazê-lo. Também identificamos o que
              nossos interesses legítimos são apropriados.
            </p>
            <p>
              Observe que podemos processar seus dados pessoais por mais de um
              fundamento legal, dependendo da finalidade específica para a qual
              estamos usando seus dados. Entre em contato conosco se precisar de
              detalhes sobre o fundamento legal específico em que estamos
              contando para processar suas informações pessoais dados em que
              mais de um terreno foi definido na tabela abaixo de.
            </p>
            <table>
              <tbody>
                <tr>
                  <td width="237">
                    <p>
                      <strong>Finalidade/Atividade</strong>
                    </p>
                  </td>
                  <td width="184">
                    <p>
                      <strong>Tipo de dados</strong>
                    </p>
                  </td>
                  <td width="291">
                    <p>
                      <strong>
                        Base legal para processamento, incluindo base de
                        interesse legítimo
                      </strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>Para registrá-lo como um novo cliente</p>
                  </td>
                  <td width="184">
                    <p>(a) Identidade</p>
                    <p>(b) Contato</p>
                  </td>
                  <td width="291">
                    <p>Desempenho de um contrato com você</p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>Para processar e entregar seu pedido, incluindo:</p>
                    <p>(a) Gerenciar pagamentos, taxas e cobranças</p>
                    <p>(b) Recolha e recupere o dinheiro que nos é devido</p>
                  </td>
                  <td width="184">
                    <p>(a) Identidade</p>
                    <p>(b) Contato</p>
                    <p>(c) Financeiro</p>
                    <p>(d) Transação</p>
                    <p>(e)Marketing e Comunicações</p>
                  </td>
                  <td width="291">
                    <p>(a) Desempenho de um contrato com você</p>
                    <p>
                      (b) Necessário para nossos interesses legítimos (para
                      recuperar dívidas devidas a nós)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>
                      Para gerenciar nosso relacionamento com você, que
                      incluirá:
                    </p>
                    <p>
                      (a) Notificá-lo sobre alterações em nossos termos ou
                      privacidade política
                    </p>
                    <p>
                      (b) Solicitando que você deixe um comentário ou responda a
                      uma pesquisa
                    </p>
                  </td>
                  <td width="184">
                    <p>(a) Identidade</p>
                    <p>(b) Contato</p>
                    <p>(c) Perfil</p>
                    <p>(d) Marketing e Comunicações</p>
                  </td>
                  <td width="291">
                    <p>(a) Desempenho de um contrato com você</p>
                    <p>(b) Necessário para cumprir uma obrigação legal</p>
                    <p>
                      (c) Necessário para nossos interesses legítimos (para
                      manter nossa registros atualizados e estudar como os
                      clientes usam nossos produtos/serviços)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>
                      Para permitir que você participe/ou complete uma pesquisa
                    </p>
                  </td>
                  <td width="184">
                    <p>(a) Identidade</p>
                    <p>(b) Contato</p>
                    <p>(c) Perfil</p>
                    <p>(d) Uso</p>
                    <p>(e) Marketing e Comunicações</p>
                  </td>
                  <td width="291">
                    <p>(a) Desempenho de um contrato com você</p>
                    <p>
                      (b) Necessário para nossos interesses legítimos (para
                      estudar como clientes usam nossos produtos/serviços, para
                      desenvolvê-los e crescer o nosso negócio)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>
                      Para administrar e proteger nosso negócio e este site
                      (incluindo solução de problemas, análise de dados, teste,
                      sistema manutenção, suporte, relatórios e hospedagem de
                      dados)&nbsp;
                    </p>
                  </td>
                  <td width="184">
                    <p>(a) Identidade</p>
                    <p>(b) Contato</p>
                    <p>(c) Técnico</p>
                  </td>
                  <td width="291">
                    <p>
                      (a) Necessário para nossos interesses legítimos (para
                      executar nosso negócio, prestação de serviços de
                      administração e TI, segurança de rede, para evitar fraudes
                      e no contexto de um reorganização empresarial ou exercício
                      de reestruturação do grupo)
                    </p>
                    <p>(b) Necessário para cumprir uma obrigação legal</p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>
                      Para fornecer conteúdo relevante do site e anúncios para
                      você e medir ou compreender a eficácia do publicidade que
                      servimos para você
                    </p>
                  </td>
                  <td width="184">
                    <p>(a) Identidade</p>
                    <p>(b) Contato</p>
                    <p>(c) Perfil</p>
                    <p>(d) Uso</p>
                    <p>(e) Marketing e Comunicações</p>
                    <p>(f) Técnico</p>
                  </td>
                  <td width="291">
                    <p>
                      Necessário para nossos interesses legítimos (para estudar
                      como clientes usam nossos produtos/serviços, para
                      desenvolvê-los, para expandir nossos negócios e informar
                      nossa estratégia de marketing)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>
                      Para usar a análise de dados para melhorar nosso site,
                      produtos/serviços, marketing, relacionamento com clientes
                      e experiências
                    </p>
                  </td>
                  <td width="184">
                    <p>(a) Técnico</p>
                    <p>(b) Uso</p>
                  </td>
                  <td width="291">
                    <p>
                      Necessário para nossos interesses legítimos (para definir
                      tipos de clientes para nossos produtos e serviços, para
                      manter nosso site atualizado e relevante, para desenvolver
                      nossos negócios e para informar nossa estratégia de
                      marketing)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="237">
                    <p>
                      Necessário para nossas legítimas (para definir tipos de
                      interesses clientes para nossos produtos e serviços, para
                      manter nosso site atualizado e relevante, para desenvolver
                      nossos negócios e para informar nossa estratégia de
                      marketing)
                    </p>
                  </td>
                  <td width="184">
                    <p>(a) Identidade</p>
                    <p>(b) Contato</p>
                    <p>(c) Técnico</p>
                    <p>(d) Uso</p>
                    <p>(e) Perfil</p>
                    <p>(f) Marketing e Comunicações</p>
                  </td>
                  <td width="291">
                    <p>
                      Necessário para nossos interesses legítimos (para
                      desenvolver nossa produtos/serviços e expandir nossos
                      negócios)
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Marketing</p>
            <p>
              Nós nos esforçamos para fornecer a você opções com relação a
              determinados usos de dados, particularmente em torno de marketing
              e publicidade.
            </p>
            <p>Ofertas promocionais nossas</p>
            <p>
              Podemos usar sua identidade, contato, técnico, uso e perfil Dados
              para formar uma visão sobre o que achamos que você pode querer ou
              precisar, ou o que pode ser do seu interesse. É assim que
              decidimos quais produtos, serviços e ofertas podem ser relevantes
              para você (chamamos isso marketing).
            </p>
            <p>
              Você receberá nossas comunicações de marketing se tiver nos
              solicitou informações ou comprou [bens ou serviços] de nós e você
              não optou por não receber esse marketing.
            </p>
            <p>marketing de terceiros</p>
            <p>
              Obteremos seu consentimento expresso antes de compartilharmos sua
              dados pessoais com terceiros para fins de marketing.
            </p>
            <p>Desativando</p>
            <p>
              Você pode pedir a nós ou a terceiros que parem de enviar marketing
              mensagens a qualquer momento entrando em contato conosco a
              qualquer momento.
            </p>
            <p>
              Quando você optar por não receber essas mensagens de marketing,
              isso não se aplicam aos dados pessoais fornecidos a nós como
              resultado de um compra de produtos/serviços, experiência em
              produtos/serviços ou outros transações.
            </p>
            <p>Biscoitos</p>
            <p>
              Nosso site usa cookies para diferenciá-lo de outros usuários de
              nosso site. Isso nos ajuda a fornecer uma boa experiência quando
              você navega em nosso site e nos permite melhorar nosso site.A
              cookie é um pequeno arquivo de letras e números que armazenamos em
              seu navegador ou o disco rígido do seu computador se você
              concordar. Os cookies contêm informações que são transferidas para
              o seu computador disco rígido.
            </p>
            <p>
              <strong>Usamos os seguintes cookies:</strong>
            </p>
            <ul>
              <li>
                <strong>N</strong>
                <strong>cookies necessários.</strong> Estes são os cookies que
                são necessários para o funcionamento do nosso site. Eles
                incluem, por exemplo, cookies que permitem que você faça login
                em áreas seguras de nosso site, use um carrinho de compras ou
                faça uso de serviços de faturamento eletrônico.
              </li>
              <li>
                <strong>Cookies analíticos ou de desempenho.</strong> Estes
                permitem reconhecer e contar o número de visitantes e ver como
                os visitantes se movem em nosso site quando o estão usando. este
                ajuda-nos a melhorar a forma como o nosso website funciona, por
                exemplo, garantir que os usuários encontrem o que procuram
                facilmente.
              </li>
              <li>
                <strong>Cookies de funcionalidade.</strong> Estes são usados
                ​​para reconhecê-lo quando você retornar ao nosso site. Isso nos
                permite personalizar nosso conteúdo para você, cumprimentá-lo
                pelo nome e lembrar suas preferências (por exemplo, sua escolha
                de idioma ou região).
              </li>
              <li>
                <strong>Cookies de segmentação.</strong> Esses cookies registram
                seu visita ao nosso site, as páginas que você visitou e os links
                você seguiu. Usaremos essas informações para tornar nossa site e
                a publicidade exibida nele mais relevante para seus interesses.
                Também podemos compartilhar essas informações com terceiros
                partes para este efeito.
              </li>
              <li>
                <strong>
                  Cookies de mídia (incluindo os de mídia social){" "}
                </strong>
                Esses cookies rastrearão seu navegador em outros sites e
                descobrir seus principais interesses. Esses cookies podem ser
                implementado por vários serviços de mídia social que usamos para
                compartilhe nosso conteúdo e informações com sua rede.
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <strong>SE VOCÊ NÃO QUER QUE UTILIZEMOS SEU CO</strong>
              <strong>O</strong>
              <strong>KIES.</strong>
            </p>
            <p>
              Se você não concorda com o uso de cookies, você pode cancelar seu
              conta. Se você acessar nossa Plataforma e/ou usar nossos serviços
              e uma mensagem pop-up de aviso sobre cookies aparece em nosso
              site, você pode optar por consentir ou recusar o compartilhamento
              de seus cookies. Por favor note, se você aceitar a política de
              cookies na janela pop-up, não consentimento adicional será
              necessário.
            </p>
            <p>
              <strong>mudança de propósito</strong>
            </p>
            <p>
              Apenas utilizaremos os seus dados pessoais para os fins para os
              quais coletado, a menos que consideremos razoavelmente que
              precisamos usá-lo por outro motivo e esse motivo é compatível com
              o original propósito. Se você deseja obter uma explicação sobre
              como o processamento para a nova finalidade é compatível com o
              original propósito, entre em contato conosco.
            </p>
            <p>
              Se precisarmos usar seus dados pessoais para uma finalidade não
              relacionada, nós iremos notificá-lo e iremos explicar a base legal
              que permite nós para fazê-lo.
            </p>
            <p>
              Observe que podemos processar seus dados pessoais sem o seu
              conhecimento ou consentimento, em conformidade com as regras
              acima, quando isso é exigido ou permitido por lei.
            </p>
            <ol start="5">
              <li>
                <strong>
                  <a name="_Toc256000004"></a>Divulgações de seus dados pessoais
                </strong>
              </li>
            </ol>
            <p>
              Podemos compartilhar seus dados pessoais com as partes definidas
              abaixo para as finalidades indicadas na tabela [
              <em>Finalidades para as quais usaremos seus dados pessoais</em>]
              acima de.
            </p>
            <ul>
              <li>Terceiros Internos.</li>
              <li>Terceiros Externos.</li>
              <li>
                Terceiros a quem podemos optar por vender, transferir ou fundir
                partes de nosso negócio ou de nossos ativos. Alternativamente,
                podemos procurar para adquirir outros negócios ou fundir-se com
                eles. Se uma mudança acontece com o nosso negócio, então os
                novos proprietários podem usar o seu dados pessoais da mesma
                forma estabelecida nesta política de privacidade.
              </li>
            </ul>
            <p>
              Exigimos que todos os terceiros respeitem a segurança do seu dados
              pessoais e tratá-los de acordo com a lei. Nós fazemos não permitir
              que nossos provedores de serviços terceirizados usem seus dados
              pessoais dados para seus próprios fins e apenas permitir que eles
              processem seus dados pessoais para finalidades especificadas e de
              acordo com nosso instruções.
            </p>
            <ol start="6">
              <li>
                <strong>
                  <a name="_Toc256000005"></a>transferências internacionais
                </strong>
              </li>
            </ol>
            <p>
              Se você concorda com nossa política de privacidade, isso significa
              que você concorda que nós pode acessar,&nbsp; processar e
              transferir dados em uma base global , tendo em consideração que
              cumprimos os requisitos da Leis de Proteção de Dados.
            </p>
            <ol start="7">
              <li>
                <strong>
                  <a name="_Toc256000006"></a>Segurança de dados
                </strong>
              </li>
            </ol>
            <p>
              Implementámos medidas de segurança adequadas para evitar que o seu
              dados pessoais sejam acidentalmente perdidos, usados ​​ou
              acessados ​​em um forma não autorizada, alterada ou divulgada.
              Além disso, limitamos acesso aos seus dados pessoais a esses
              funcionários, agentes, contratados e outros terceiros que tenham
              uma necessidade comercial de conhecer. Eles apenas processarão
              seus dados pessoais em nosso instruções e estão sujeitas a um
              dever de confidencialidade.
            </p>
            <p>
              Implementamos procedimentos para lidar com qualquer suspeita
              violação de dados pessoais e notificará você e qualquer regulador
              de uma violação quando somos legalmente obrigados a fazê-lo.
            </p>
            <ol start="8">
              <li>
                <strong>
                  <a name="_Toc256000007"></a>Retenção de dados
                </strong>
              </li>
            </ol>
            <p>Por quanto tempo você usará meus dados pessoais?</p>
            <p>
              Só reteremos seus dados pessoais pelo tempo razoavelmente
              necessários para cumprir os propósitos para os quais os coletamos,
              incluindo para efeitos de satisfação de quaisquer disposições
              legais, regulamentares, fiscais, requisitos de contabilidade ou
              relatórios. Podemos reter seus dados pessoais dados por um período
              mais longo em caso de reclamação ou se nós acreditam razoavelmente
              que há uma perspectiva de litígio em relação a nosso
              relacionamento com você.
            </p>
            <p>
              Para determinar o período de retenção apropriado para dados
              pessoais, consideramos a quantidade, natureza e sensibilidade do
              pessoal dados, o risco potencial de dano por uso não autorizado ou
              divulgação dos seus dados pessoais, as finalidades para as quais
              processar seus dados pessoais e se podemos alcançar esses
              propósitos por outros meios, e as disposições legais, requisitos
              regulamentares, fiscais, contabilísticos ou outros.
            </p>
            <p>
              Em algumas circunstâncias, anonimizaremos seus dados pessoais
              (portanto, que não pode mais ser associado a você) para pesquisa
              ou fins estatísticos, caso em que podemos usar esta informação
              indefinidamente sem aviso prévio a você.
            </p>
            <ol start="9">
              <li>
                <strong>
                  <a name="_Toc256000008"></a>Seus direitos legais
                </strong>
              </li>
            </ol>
            <p>
              Sob certas circunstâncias, você tem direitos sob proteção de dados
              leis em relação aos seus dados pessoais. Por favor, clique nos
              links abaixo para saber mais sobre esses direitos:
            </p>
            <ul>
              <li>
                [<em>Solicitar acesso aos seus dados pessoais</em>].
              </li>
              <li>
                [<em>Solicitar a correção dos seus dados pessoais</em>].
              </li>
              <li>
                [<em>Solicitar o apagamento dos seus dados pessoais</em>].
              </li>
              <li>
                [<em>Opor-se ao tratamento dos seus dados pessoais</em>].
              </li>
              <li>
                [
                <em>
                  Solicitar restrição de processamento de seus dados pessoais
                </em>
                ].
              </li>
              <li>
                [<em>Solicitar a transferência dos seus dados pessoais</em>].
              </li>
              <li>
                [<em>Direito de retirar o consentimento</em>].
              </li>
            </ul>
            <p>
              Se você deseja exercer algum dos direitos descritos acima, por
              favor, Contate-Nos.
            </p>
            <p>Nenhuma taxa geralmente exigida</p>
            <p>
              Você não terá que pagar uma taxa para acessar seus dados pessoais
              (ou para exercer qualquer um dos outros direitos). No entanto,
              podemos cobrar um taxa razoável se o seu pedido for claramente
              infundado, repetitivo ou excessivo. Em alternativa, podemos
              recusar-nos a cumprir as suas pedido nestas circunstâncias.
            </p>
            <p>
              <strong>O que podemos precisar de você</strong>
            </p>
            <p>
              Podemos precisar solicitar informações específicas de você para
              nos ajudar confirmar a sua identidade e garantir o seu direito de
              acesso ao seu dados pessoais (ou para exercer qualquer um dos seus
              outros direitos). Isto é um medida de segurança para garantir que
              os dados pessoais não sejam divulgados a qualquer pessoa que não
              tenha o direito de recebê-lo. Também podemos entrar em contato com
              você pedir-lhe mais informações em relação ao seu pedido de
              agilizar nossa resposta.
            </p>
            <p>
              <strong>Limite de tempo para responder</strong>
            </p>
            <p>
              Tentamos responder a todos os pedidos legítimos no prazo de um
              mês. Ocasionalmente, pode demorar mais de um mês se o seu pedido é
              particularmente complexo ou você fez uma série de solicitações.
              Dentro neste caso, iremos notificá-lo e mantê-lo atualizado.
            </p>
            <ol start="10">
              <li>
                <strong>
                  <a name="_Toc256000009"></a>Glossário
                </strong>
              </li>
            </ol>
            <p>
              <strong>BASE LEGAL</strong>
            </p>
            <p>
              Interesse legítimo significa o interesse de nossos negócios em
              conduzindo e gerenciando nossos negócios para nos permitir dar a
              você o melhor serviço/produto e a melhor e mais segura
              experiência. Nós certifique-se de que consideramos e equilibramos
              qualquer impacto potencial sobre você (ambos positivos e
              negativos) e seus direitos antes de processarmos seus dados
              pessoais para nossos interesses legítimos. nós não usamos seus
              dados pessoais para atividades onde nossos interesses são
              substituído pelo impacto sobre você (a menos que tenhamos seu
              consentimento ou são exigidos ou permitidos por lei). você pode
              obter mais informações sobre como avaliamos nossos interesses
              legítimos contra qualquer impacto potencial sobre você em relação
              a atividades entrando em contato conosco.
            </p>
            <p>
              Execução do Contrato significa processar seus dados onde eles são
              necessário para a execução de um contrato do qual você é um parte
              ou tomar medidas a seu pedido antes de entrar em tal contrato.
            </p>
            <p>
              Cumprir uma obrigação legal significa tratar os seus dados
              pessoais quando for necessário para o cumprimento de uma obrigação
              legal que estamos sujeitos.
            </p>
            <p>
              <strong>TERCEIROS</strong>
            </p>
            <p>Terceiros Internos</p>
            <p>
              Outras afiliadas da Kinetic&nbsp; [agindo como controladores
              conjuntos ou processadores] e que estão baseados em outras
              jurisdições e fornecem Serviços de administração de sistemas e TI
              e assumir a liderança comunicando.
            </p>
            <p>
              <strong>Terceiros Externos</strong>
            </p>
            <ul>
              <li>
                Prestadores de serviços [atuando como processadores] baseados em
                outros jurisdições que fornecem serviços de administração de
                sistemas e TI.
              </li>
              <li>
                Consultores profissionais que atuam como processadores ou
                controladores conjuntos incluindo advogados, banqueiros,
                auditores e seguradoras com sede nos Emirados Árabes Unidos ou
                outras jurisdições que prestam serviços de consultoria, serviços
                bancários, jurídicos, seguros e serviços de contabilidade.
              </li>
              <li>
                Receita HM &amp; Alfândega, reguladores e outras autoridades
                [atuando como processadores ou controladores conjuntos] com sede
                nos Emirados Árabes Unidos que exigir relatórios de atividades
                de processamento em certos circunstâncias.
              </li>
              <li>
                Quaisquer outros terceiros que forneçam pesquisas de mercado ou
                outros serviços ou com quem a Kinetic tenha contrato e os
                forneça um serviço.
              </li>
            </ul>
            <p>
              <strong>SEUS DIREITOS LEGAIS</strong>
            </p>
            <p>Você tem o direito de:</p>
            <p>
              Solicitar acesso aos seus dados pessoais (comumente conhecidos
              como “data solicitação de acesso do assunto”). Isso permite que
              você receba uma cópia de os dados pessoais que mantemos sobre você
              e para verificar se estamos processá-lo legalmente.
            </p>
            <p>
              Solicite a correção dos dados pessoais que mantemos sobre você.
              Isso permite que você tenha quaisquer dados incompletos ou
              imprecisos que mantemos sobre você corrigido, embora possamos
              precisar verificar a precisão de os novos dados que você nos
              fornecer.
            </p>
            <p>
              Solicite o apagamento dos seus dados pessoais. Isso permite que
              você nos pergunte para excluir ou remover dados pessoais onde não
              há um bom motivo para continuarmos a processá-lo. Você também tem
              o direito de nos perguntar para excluir ou remover seus dados
              pessoais onde você tiver exerceu seu direito de se opor ao
              processamento (veja abaixo), onde pode ter processado suas
              informações ilegalmente ou onde estamos obrigados a apagar seus
              dados pessoais para cumprir a legislação local. Observe, no
              entanto, que nem sempre podemos cumprir suas pedido de apagamento
              por razões legais específicas que serão notificado a você, se
              aplicável, no momento de sua solicitação.
            </p>
            <p>
              Opor-se ao processamento de seus dados pessoais onde estamos
              confiando um interesse legítimo (ou de terceiros) e há algo sobre
              sua situação particular que faz você querer opor-se ao
              processamento por este motivo, pois você sente que isso afeta o
              seu direitos e liberdades fundamentais. Você também tem o direito
              de se opor onde estamos processando seus dados pessoais para
              marketing direto propósitos. Em alguns casos, podemos demonstrar
              que temos motivos legítimos convincentes para processar suas
              informações que anular seus direitos e liberdades.
            </p>
            <p>
              Solicitar restrição de processamento de seus dados pessoais. este
              permite que você nos peça para suspender o processamento de seus
              dados pessoais dados nos seguintes cenários:
            </p>
            <ul>
              <li>Se você deseja que estabeleçamos a precisão dos dados.</li>
              <li>
                Onde nosso uso dos dados é ilegal, mas você não deseja que
                apague isso.
              </li>
              <li>
                Onde você precisa que mantenhamos os dados, mesmo que não
                exijamos mais conforme necessário para estabelecer, exercer ou
                defender reivindicações legais.
              </li>
              <li>
                Você se opôs ao uso de seus dados, mas precisamos verificar se
                temos motivos legítimos para usá-lo.
              </li>
            </ul>
            <p>
              Solicitar a transferência de seus dados pessoais para você ou para
              um terceiro Festa. Forneceremos a você, ou a um terceiro que você
              escolheu, seus dados pessoais de forma estruturada, comumente
              usada, formato legível por máquina. Note que este direito só se
              aplica a informações automatizadas que você inicialmente forneceu
              consentimento para nós usar ou onde usamos as informações para
              executar um contrato com vocês.
            </p>
            <p>
              Retirar o consentimento a qualquer momento, quando estivermos
              contando com o consentimento para processar os seus dados
              pessoais. No entanto, isso não afetará o legalidade de qualquer
              processamento realizado antes de você retirar seu consentimento.
              Se você retirar seu consentimento, talvez não possamos fornecer
              determinados produtos ou serviços a você. Iremos aconselhá-lo se
              este é o caso no momento em que você retira seu consentimento.
            </p>
          </div>
        </article>
      </div>
      <Footer />
    </div>
  );
}
export default PrivacyPt;
