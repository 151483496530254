import React, { Component } from "react";
import Route from "./Routes/routeIndex";
import "./App.css";
import ptJson from "./localize/brazile.json";
import enJson from "./localize/english.json";

//import style
import "./assets/css/pe-icon-7.css";
import "./assets/css/materialdesignicons.min.css";
import "./assets/scss/theme.scss";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

function App() {
  const language = localStorage.getItem("language");
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        pt: {
          translation: ptJson,
        },
        en: {
          translation: enJson,
        },
      },
      lng: language, // if you're using a language detector, do not define the lng option
      fallbackLng: "pt",

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
