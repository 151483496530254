import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import FooterLink from "./FooterLink";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  const links = [
    {
      id: 3,
      title: t("legal"),
      child: [{ title: t("privacyLink"), link: "/privacy-policy" }],
    },
  ];
  return (
    <React.Fragment>
      <section className="footer-bg">
        <Container>
          <Row>
            <Col lg={12}>
              <Row>
                {links.map((link, key) => (
                  <Col lg={4} key={key}>
                    <div className="mb-5">
                      <p className="text-uppercase text-dark footer-title mb-4">
                        {link.title}
                      </p>
                      <ul className="list-unstyled footer-sub-menu">
                        {link.child.map((linkItem, key) => (
                          <li className="f-14" key={key}>
                            <Link to={linkItem.link} className="text-muted">
                              {linkItem.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <FooterLink />
    </React.Fragment>
  );
}
